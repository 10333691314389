import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Block from 'components/blocks/Block'

const CaseStudy = ({ data: { pg } }) => (
  <DefaultLayout {...pg.seo}>
    {pg.blocks?.map(block => (
      <Block {...block} key={block?._key} />
    ))}
  </DefaultLayout>
)

export default CaseStudy

export const query = graphql`query CaseStudy($id: String) {
  pg: sanityCaseStudyPg(id: {eq: $id}) {
    blocks {
      ...descriptionColumns
      ...heroBlock
      ...whySection
      ...caseStudyContent
      ...testimonial
      ...caseStudyResults
      ...textWithBg
    }
    seo { title description }
  }
}`
